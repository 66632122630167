<template>
  <div>
    <!-- Menu Superior -->
    <div class="theme-hero-area theme-hero-area-primary">
      <div class="theme-hero-area-bg-wrap">
        <div
          class="theme-hero-area-bg ws-action"
          :style="{'background-image': 'url(' + require('@/assets/img/bg-home-flight.jpg') + ')'}"
          data-parallax="true"
        ></div>
        <div class="theme-hero-area-mask theme-hero-area-mask-strong"></div>
      </div>
      <div class="theme-hero-area-body">
        <div class="container">
          <div class="theme-search-area theme-search-area-white _mt-70">
            <div class="row">
              <div class="col-md-12">
                <!-- <flight-search :flight="flight"></flight-search> -->
                <box-search ref="boxsearch" :flight="flight"></box-search>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Menu Superior -->
    <!-- Conteudo -->
    <div class="theme-page-section theme-page-section-gray">
      <div class="container">
        <div class="row row-col-static" id="sticky-parent" data-gutter="20">
          <div class="col-md-2-5">
            <div class="_mob-h">
              <div class="theme-search-results-sidebar-sections _mb-20 _br-2 theme-search-results-sidebar-sections-white-wrap">
                <div class="theme-search-results-sidebar-sections _mb-20 _br-2">
                  <div class="theme-search-results-sidebar-section">
                    <h5 class="theme-search-results-sidebar-section-title">Paradas</h5>
                    <div class="theme-search-results-sidebar-section-checkbox-list">
                      <div class="theme-search-results-sidebar-section-checkbox-list-items">
                        <div class="theme-search-results-sidebar-section-checkbox-list-item">
                          <p-check
                            class="p-svg p-default p-fill p-smooth"
                            type="checkbox"
                            name="check"
                            color="primary"
                            v-model="filter.segments_1"
                          >
                            <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                              <path
                                d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                style="stroke: white;fill:white"
                              ></path>
                            </svg>
                            <span class="pretty-space">Direto</span>
                          </p-check>
                          <span class="theme-search-results-sidebar-section-checkbox-list-amount"></span>
                        </div>

                        <div class="theme-search-results-sidebar-section-checkbox-list-item">
                          <p-check
                            class="p-svg p-default p-fill p-smooth"
                            type="checkbox"
                            name="check"
                            color="primary"
                            v-model="filter.segments_2"
                          >
                            <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                              <path
                                d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                style="stroke: white;fill:white"
                              ></path>
                            </svg>
                            <span class="pretty-space">1 parada</span>
                          </p-check>
                          <span class="theme-search-results-sidebar-section-checkbox-list-amount"></span>
                        </div>

                        <div class="theme-search-results-sidebar-section-checkbox-list-item">
                          <p-check
                            class="p-svg p-default p-fill p-smooth"
                            type="checkbox"
                            name="check"
                            color="primary"
                            v-model="filter.segments_3"
                          >
                            <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                              <path
                                d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                style="stroke: white;fill:white"
                              ></path>
                            </svg>
                            <span class="pretty-space">2 paradas ou mais</span>
                          </p-check>
                          <span class="theme-search-results-sidebar-section-checkbox-list-amount"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="theme-search-results-sidebar-section">
                    <h5 class="theme-search-results-sidebar-section-title">Companhias aéreas</h5>
                    <div class="theme-search-results-sidebar-section-checkbox-list">
                      <div class="theme-search-results-sidebar-section-checkbox-list-items">
                        <div class="theme-search-results-sidebar-section-checkbox-list-item" v-for="(carrier, index) in carriers" :key="index">
                          <p-check
                            class="p-svg p-default p-fill p-smooth"
                            type="checkbox"
                            name="check"
                            color="primary"
                            v-model="filter.airlines[carrier]"
                            :true-value="true"
                            :false-value="false"

                          >
                            <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                              <path
                                d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                style="stroke: white;fill:white"
                              ></path>
                            </svg>
                            <span class="pretty-space">{{carrier}}</span>
                          </p-check>
                          <span class="theme-search-results-sidebar-section-checkbox-list-amount"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="theme-search-results-sidebar-section">
                    <h5 class="theme-search-results-sidebar-section-title">Preço</h5>
                    <div class="theme-search-results-sidebar-section-price">
                      <vue-slider
                        v-model="filter.price"
                        ref="slider"
                        :min="0"
                        :max="10000"
                        :tooltipDir="['bottom', 'bottom']"
                        :formatter="'R$ {value}'"
                        :speed="1"
                        :interval="100"
                        :lazy="true"
                      ></vue-slider>
                    </div>
                  </div>
                  <div class="theme-search-results-sidebar-section">
                    <h5 class="theme-search-results-sidebar-section-title">Horário de saída</h5>
                    <div class="theme-search-results-sidebar-section-price">
                      <vue-slider
                        v-model="filter.going_time"
                        ref="slider"
                        :min="0"
                        :max="24"
                        :lazy="true"
                        :tooltipDir="['bottom', 'bottom']"
                        :formatter="format_time"
                        :speed="1"
                        :interval="0.25"
                      ></vue-slider>
                      <!--<input id="price-slider" name="price-slider" data-min="100" data-max="10000">-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-7">
            <!-- Selected Flights -->
            <template v-if="(flight.flights.outbound.id || flight.flights.inbound.id)  && !loading">
              <picked-flights
                :journey="flight"
                @handleRemove="cleanSelectedFlights"
                @handleReprice="rePrice"
                @handleReserve="flight_reserve">
              </picked-flights>
            </template>
            <!-- // Selected Flights -->

            <article>
              <h2
                v-if="!flights.length && loading==false"
                class="h4">
                Nenhum voo encontrado. Por favor refaça sua busca.
              </h2>

              <p v-if="total_outbound_results">
                <i class="fa fa-calendar-check-o fa-fw"></i>
                <strong>Ida: {{ total_outbound_results }} voos encontrados</strong>
              </p>
              <p v-if="total_inbound_results">
                <i class="fa fa-calendar-check-o fa-fw"></i>
                <strong>Volta: {{ total_inbound_results }} voos encontrados</strong>
              </p>
            </article>

            <!-- Prices Grid -->
            <template v-if="flights.length > 0 && lowestPricesGrid">
              <prices-grid
                :lowest-prices="lowestPricesGrid"></prices-grid>
            </template>
            <!-- // Prices Grid -->

            <!-- Results List -->
            <results-list
              v-if="flights.length > 0"
              :results="flights"
              :picked-flight="flight"
              @handlePrices="flight_get_prices"></results-list>
            <!-- // Results List -->
          </div>
          <div class="col-md-2-5" data-hell>
            <!-- /Valores -->
            <div class="sticky-col">
              <div class="theme-ad hidden-xs hidden-sm">
                <a class="theme-ad-link" href="#"></a>
                <p class="theme-ad-sign">Propaganda</p>
                <img
                  class="theme-ad-img"
                  src="@/assets/img/banner-gol.png"
                  alt="Tarifas imperdiveis para seu destino"
                  title="Propaganda"
                >
              </div>
            </div>
            <!-- Valores -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Conteudo -->
    <lockscreen :loading="loading"></lockscreen>
  </div>
</template>

<script>
import _ from 'underscore';
import moment from 'moment';
import vueSlider from "vue-slider-component";

import BoxSearch from "@/components/template/boxsearch.vue";
import ResultsList from '@/views/flights/search/ResultsList';
import PickedFlights from '@/views/flights/search/PickedFlights';
import PricesGrid from '@/views/flights/search/PricesGrid';

import lockscreen from "@/components/lockscreen/index";

import flights from "@/mixins/flights";
import travelers from "@/mixins/travelers";

export default {
  mixins: [flights, travelers],
  components: {
    BoxSearch,
    ResultsList,
    PickedFlights,
    PricesGrid,
    lockscreen,
    vueSlider
  },
  data() {
    return {
      lowestPricesGrid: null,
      results: [],
      flights: [],
      carriers: []
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    rePrice(price) {
      this.flight_get_prices(this.flight.flights.inbound.id ? this.flight.flights.inbound : this.flight.flights.outbound, null, price)
    },
    format_time(val) {
      if(val){
        return moment.duration(val, 'hours').format('HH:mm');
      }
      return '00:00';
    },
    fetchData() {
      let origin = this.$route.params.origin;
      let destination = this.$route.params.destination;
      let adults = this.$route.params.adults;
      let going = this.$route.params.outbound;
      let returning = this.$route.params.inbound;

      let outbound_and_inbound;
      if (this.$route.params.inbound) {
        outbound_and_inbound = 'true';
      } else {
        outbound_and_inbound = 'false';
      }

      this.flight.outbound_and_inbound = outbound_and_inbound;

      this.loading = true;
      return this.$http({
        url: '/api/flight/availability',
        method: 'GET',
        params: {
          origin: origin,
          destination: destination,
          going: going,
          returning: returning,
          adults: adults,
        }
      }).then((response) => {
        this.results = response.data.results;

        this.loading = false;
        if (!this.results.length) {
          this.$swal({
            title: "Nenhum voo disponível para esse trecho e data escolhida",
            type: "error",
            confirmButtonText: "SAIR"
          });
          return;
        }

        this.getCarriers(this.results);

        this.filterResults().then(() => {
          this.groupSlowestPrice(this.flights);
          this.loading = false;
        })
      }).catch(() => {
        this.loading = false;
        this.$swal({
          title: "Indisponibilidade na pesquisa",
          type: "error",
          confirmButtonText: "SAIR"
        });
      })
    },
    groupSlowestPrice(flights) {
      let carrier_prices = {};
      flights = _.clone(flights);
      flights.forEach(function(journey) {
        // if (Object.keys(carrier_prices).length > 5) return;
        if(journey.flights.length == 0) return;
        if (typeof carrier_prices[journey.carrier.iata_code] == 'undefined' ) {
          carrier_prices[journey.carrier.iata_code] = {};
        }

        journey.flights.forEach((flight) => {
          let stops = flight.segments.length;
          let stop_label;

          if (flight.direct) {
            stop_label = 0;
          } else {
            stop_label = stops - 1;
            if (stop_label >= 2) {
              stop_label = 2;
            }
          }

          if (typeof carrier_prices[journey.carrier.iata_code][stop_label] == 'undefined') {
            carrier_prices[journey.carrier.iata_code][stop_label] = []
          }
          carrier_prices[journey.carrier.iata_code][stop_label] = carrier_prices[journey.carrier.iata_code][stop_label].concat(flight.prices.map((el) => { return el.base}));
        });

      });

      let prices_grid = {
        'header': [],
        'values': {}
      }

      let last_best_price = {};
      let last_dir_key;
      let last_carrier_name;
      Object.keys(carrier_prices).forEach((carrier_name) => {
        let carrier = carrier_prices[carrier_name];
        // prices_grid['header'].push(carrier_name);
        Object.keys(carrier).forEach((dir_key) => {
          if (typeof prices_grid['values'][dir_key] == 'undefined') {
            prices_grid['values'][dir_key] = {}
            prices_grid['values'][dir_key];
          }

          let prices = carrier[dir_key];
          let price = prices.sort((a, b)=>{
            if (a<b) return -1;
            if (a>b) return 1;
            return 0;
          })[0];

          if (typeof last_best_price[last_dir_key] != 'undefined') {

            if (price < last_best_price[last_dir_key][last_carrier_name].price){
              // renew the best price
              last_dir_key = dir_key;
              last_carrier_name = carrier_name;

              // last_best_price = {}
              last_best_price[last_dir_key] = {};
              last_best_price[last_dir_key][last_carrier_name] = {
                'best_price': true,
                'price': price
              };
            }
          } else {
            // first time is the best price
            last_best_price[dir_key] = {}
            last_best_price[dir_key][carrier_name] = {
              'best_price': true,
              'price': price
            };
            last_dir_key = dir_key;
            last_carrier_name = carrier_name;
          }


          prices_grid['values'][dir_key][carrier_name] = {
            best_price: false,
            price: price
          };
        });
      });

      if(Object.keys(prices_grid.values).length) {
        Object.keys(last_best_price).forEach(function(idx) {
          let carrier_idx = Object.keys(last_best_price[idx])[0];
          prices_grid['values'][idx][carrier_idx] = Object.assign(prices_grid['values'][idx][carrier_idx], last_best_price[idx][carrier_idx]);

        })
      }

      // mount grid header based on order price
      let tmp_price_header_company = [];
      Object.keys(prices_grid['values']).forEach((dir) => {
        Object.keys(prices_grid['values'][dir]).forEach((carrier) => {
          let carrier_price = prices_grid['values'][dir][carrier]
          tmp_price_header_company.push(Object.assign({'carrier': carrier, dir: parseInt(dir)}, carrier_price));
        });
      });

      tmp_price_header_company = _.sortBy(tmp_price_header_company, 'price');
      let direct = _.findWhere(tmp_price_header_company, { dir: 0 });

      prices_grid['carriers'] = _.object(_.map(_.uniq(flights.map((flight) => flight.carrier), carrier => carrier.id), carrier => ([carrier.iata_code, carrier])));
      prices_grid['header'] = (direct) ? _.uniq([direct.carrier].concat(_.pluck(tmp_price_header_company, 'carrier'))) : _.uniq([].concat(_.pluck(tmp_price_header_company, 'carrier')));

      // if (direct) {
      //   prices_grid['header'] = _.uniq([direct.carrier].concat(_.pluck(tmp_price_header_company, 'carrier')));
      // } else {
      //   prices_grid['header'] = _.uniq([].concat(_.pluck(tmp_price_header_company, 'carrier')));
      // }

      this.lowestPricesGrid = prices_grid;
    },
    async filterResults () {
      const flights = this.results.length ? JSON.parse(JSON.stringify(this.results)) : [];
      const sortedFlights = this.sortFlightsByPrices(flights);
      this.flights = _.chain([...sortedFlights])
        .sortBy(item => item.flights[0].segments[0].date_departure)
        .sortBy(item => item.flights[0].segments.length)
        .sortBy('lowest_price')
        .value();

      await this.flights.forEach((item, index) => {
        let itemFlights = [];

        item.flights.forEach((flight) => {

          let response = false;
          let has_airline = false;
          Object.keys(this.filter.airlines).forEach((el) => {
            if (!has_airline) {
              if (this.filter.airlines[el]==true) {
                has_airline = true;
              }
            }
          });

          if (has_airline) {
            Object.keys(this.filter.airlines).forEach(() => {
              if (this.filter.airlines[item.carrier.name] == true) {

                if (flight.prices[0].base >= this.filter.price[0] && flight.prices[0].base <= this.filter.price[1]) {
                  if (this.filter.segments_1 && !response) {
                    response = flight.segments.length == 1;
                  }
                  if (this.filter.segments_2 && !response) {
                    response = flight.segments.length == 2;
                  }
                  if (this.filter.segments_3 && !response) {
                    response = flight.segments.length >= 3;
                  }
                  if(!this.filter.segments_1 && !this.filter.segments_2 && !this.filter.segments_3){
                    response = true
                  }
                }
              }
            })
          } else {
            if (flight.prices[0].base >= this.filter.price[0] && flight.prices[0].base <= this.filter.price[1]) {
              if (this.filter.segments_1 && !response) {
                // console.log('segment_1')
                response = flight.segments.length == 1;
              }
              if (this.filter.segments_2 && !response) {
                // console.log('segment_2')
                response = flight.segments.length == 2;
              }
              if (this.filter.segments_3 && !response) {
                // console.log('segment_3')
                response = flight.segments.length >= 3;
              }
              if(!this.filter.segments_1 && !this.filter.segments_2 && !this.filter.segments_3){
                response = true
              }
              // flight.segments[0]
            }
          }

          if (response) {

            let start = moment.duration(this.filter.going_time[0], 'hours').asHours();
            let end = moment.duration(this.filter.going_time[1], 'hours').asHours();

            let flight_departure_start = moment.duration(moment(flight.segments[0].date_departure).format('HH:mm:ss')).asHours();
            let flight_departure_end = moment.duration(moment(flight.segments[0].date_departure).format('HH:mm:ss')).asHours();

            if (flight_departure_start >= start && flight_departure_end <= end) {
              response = true;
            } else {
              response = false;
            }

          }

          if (response){ itemFlights.push(flight) }
        });

        item.flights = itemFlights;

        if(this.flights.length === index+1){
          // this.loading = false;
        }
      });
    }
  },
  watch: {
    $route(/*to, from*/) {
      this.cleanSelectedFlights().then(() => {
        this.carriers = []
        this.fetchData();
      });
    },
    filter: {
      handler() {
        this.$nextTick(() => {
          this.filterResults().then(() => {
            this.groupSlowestPrice(this.flights);
          });
        });
      },
      deep: true
    },
    results: function () {
      this.filterResults().then(() => {
        this.groupSlowestPrice(this.flights);
      });
    },
  }
};
</script>
